@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.ff-poppins {
  font-family: "Poppins", system-ui !important;
}

.special-label {
  display: none;
}

.form-input {
  height: 56px !important;
  background: #f3f3f3 !important;
  border: 1px solid #f0f0f0 !important;
  border-radius: 8px !important;
}
.form-input::placeholder {
  color: #6a6c77 !important;
}
.react-tel-input .form-control {
  height: 56px !important;
  background: #f3f3f3 !important;
  border: 1px solid #f0f0f0 !important;
  padding-left: 76px !important;
}

.react-tel-input .selected-flag {
  background: #e7e7e7 !important;
  width: 64px !important;
}

.react-tel-input .selected-flag .flag {
  position: absolute;
  top: 50%;
  margin-top: -5px;
  left: 50% !important;
  transform: translateX(-50%) !important;
}

.get-started-button {
  height: 56px;
  border-radius: 8px;
}

.drop-down-option {
  color: #6a6c77 !important;
}

.custom-tab-active {
  background: #de5200 !important;
  color: #fff !important;
  border-radius: 44px;
  height: 41px;
  color: #fff !important;
  width: 200px;
}
.custom-tab {
  background: transparent !important;
  color: #fff !important;
  border-radius: 44px;
  height: 41px;
  color: #585858 !important;
  width: 200px;
}

.tabs-div {
  max-width: 600px;
  width: 100%;
  overflow-x: auto;
  margin: 0 auto;
  background: #f5f5f5;
  border-radius: 56px;
}

.shadow-sm {
  box-shadow: 0px 0px 23.6px 0px #0000000a !important;
}

.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: unset !important;
  box-shadow: unset !important;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: unset !important;
  border-bottom: 1px solid #ececec !important;
}

.accordion-button:focus {
  z-index: 3;
  outline: 0;
  box-shadow: unset !important;
}

.accordion-item:last-of-type {
  border-bottom-right-radius: unset !important;
  border-bottom-left-radius: unset !important;
}

.progress-bar-container {
  height: 6px;
  background-color: #ffffff;
  position: relative;
  margin-top: 20px;
  border-radius: 3px;
  max-width: 89px;
  overflow: hidden;
}

.progress-bar {
  height: 100%;
  width: 33.33% !important; /* Fixed width for each segment */
  background-color: #de5200 !important; /* Progress bar color */
  position: absolute;
  top: 0;
  transition: left 0.5s ease !important; /* Smooth transition for the shifting effect */
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.fade-in {
  animation: fadeIn 0.5s ease-in-out;
}

.fade-out {
  animation: fadeOut 0.5s ease-in-out;
}

.play-apple-btn {
  width: 150px;
  height: 46px;
}

.newsletter-div {
  max-width: 420px;
  height: 56px;
  border-radius: 8px;
}

@media (min-width: 1200px) {
  .play-apple-btn {
    width: 244px;
    height: 56px;
  }
  .newsletter-div {
    max-width: 500px;
    height: 56px;
    border-radius: 8px;
  }
}
@media (min-width: 1440px) {
  .header-height {
    height: 100vh;
  }
}
