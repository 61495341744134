:root {
  --fs-12: 12px;
  --fs-14: 14px;
  --fs-21: 21px;
  --fs-22: 22px;
  --fs-24: 24px;
  --fs-53: 53px;
}

.fs_12 {
  font-size: var(--fs-12);
}
.fs_14 {
  font-size: var(--fs-14);
}
.fs_21 {
  font-size: var(--fs-21);
}
.fs_22 {
  font-size: var(--fs-22);
}
.fs_24 {
  font-size: var(--fs-24);
}
.fs_53 {
  font-size: var(--fs-53);
}

.color-orange {
  color: #de5200;
}
.bg-color-orange {
  background-color: #de5200 !important;
  border: 1px solid #de5200 !important;
}
.bg-light-orange {
  color: #fff9f5 !important;
}
.color-grey {
  color: #80838e;
}
.color-dark-grey {
  color: #3f414a;
}
.bg-color-dark-grey {
  background-color: #3f414a;
}

.color-light-black {
  color: #16161699;
}

.custom-padding {
  padding-right: 40px;
}

@media (max-width: 576px) {
  :root {
    --fs-12: 12px;
    --fs-14: 14px;
    --fs-21: 21px;
    --fs-22: 22px;
    --fs-24: 24px;
    --fs-53: 36px;
  }
}
